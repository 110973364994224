a {
  text-decoration: none;
  color: inherit !important;
}

.footer {
  color: #ecc94b !important;
}

li {
  list-style-type: none;
}

.css-selector {
  background: linear-gradient(
    180deg,
    #484848,
    #823737,
    #c5283d,
    #e9724c,
    #fca223
  );
  background-size: 800% 800%;

  -webkit-animation: fade 19s ease infinite;
  -moz-animation: fade 19s ease infinite;
  animation: fade 19s ease infinite;
}

@-webkit-keyframes fade {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes fade {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes fade {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

/* Colour Palette:
lightgray - #e7e7eb
darkyellow - #fca223
steel - #fca223
slate - #5c5c6c
smokeash - #9c949c
warmblue - #007580
indigo - #282846
*/

/* Dark Mode Colour Palette:
eggplant - #1c1427
smokypurple - #40394a
mintgreen - #7eca9c
seaformgreen - #ccffbd
*/
